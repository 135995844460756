import React, { FunctionComponent, useState } from 'react';
import { Book, BooksUtil } from '../../data/books';
import ReactModal from 'react-modal';
import { BookCover } from '../header/bookCover';

type MyBooksProps = {
  books: Book[];
};

export const MyBooks: FunctionComponent<MyBooksProps> = ({ books }) => {
  const [openBook, setOpenBook] = useState<Book | undefined>(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  const onBookSelected = (book: Book) => {
    setOpenBook(book);
    setModalOpen(true);
  };

  const onCloseModal = () => {
    setModalOpen(false);
  };

  const readNowLink = openBook?.readNowUrl ? (
    <a href={openBook?.readNowUrl} target={'_blank'} rel={'noreferrer'}>
      <h2>Jetzt lesen!</h2>
    </a>
  ) : (
    ''
  );

  return (
    <div className={'my-books'}>
      <ReactModal
        isOpen={modalOpen}
        contentLabel="onRequestClose Example"
        onRequestClose={onCloseModal}
        className="modal book-modal"
        overlayClassName="modal-overlay"
        closeTimeoutMS={500}
      >
        <div className={'book-hero'}>
          <BookCover book={openBook} />
        </div>
        <div className={'description-container'}>
          <h1>{BooksUtil.getTitle(openBook)}</h1>
          <h2>{BooksUtil.getSubtitle(openBook)}</h2>
          <p>{BooksUtil.getDescription(openBook)}</p>
          <p>{readNowLink}</p>
          <img src={'icons/back.png'} onClick={onCloseModal} alt={'Go back'} />
        </div>
      </ReactModal>
      {books.map((book, i) => (
        <BookCover key={i} onPress={() => onBookSelected(book)} book={book} />
      ))}
    </div>
  );
};
