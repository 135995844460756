import React, { FunctionComponent, useEffect } from 'react';
import { Vendors } from '../vendors';
import { Book } from '../../data/books';

type BookCoverProps = {
  book?: Book;
  onPress?: any;
};

export const BookCover: FunctionComponent<BookCoverProps> = ({ book, onPress }) => {
  const coverStyle = {
    backgroundImage: "url('books/" + (book?.coverName ? book.coverName : book?.title) + ".png')",
  };

  const getTitleElement = () => {
    if (book?.isReleased) {
      return undefined;
    }

    return book?.hideDetails ? (
      <h1 className={'hidden-info'}>?</h1>
    ) : (
      <>
        <h1>{book?.title}</h1>
        <h2>Arbeitstitel</h2>
        <h2>{book?.genre}</h2>
      </>
    );
  };

  const getPublisherElement = () => {
    if (book?.isReleased) {
      return undefined;
    }

    return book?.hideDetails ? '' : `${book?.publisher} | ${book?.year}`;
  };

  const getBadge = () => {
    if (book?.publisher?.toLowerCase() === 'wattpad') {
      return <div className={'badge wattpad'} />;
    }

    return undefined;
  };

  const innerContent = book?.isFinalCover ? undefined : (
    <>
      <div className={'spacer'} />
      <div className={'container'}>{getTitleElement()}</div>
      <div className={'spacer'} />
      <div className={'container'}>
        <h2>{getPublisherElement()}</h2>
      </div>
    </>
  );

  return (
    <>
      <div className={'cover small'} onClick={onPress}>
        {getBadge()}
        <div className={`inner cover-${book?.coverName}`} style={coverStyle}>
          {innerContent}
        </div>
      </div>
    </>
  );
};
