import { CSSProperties } from 'react';

export type Award = {
  id: string;
  title: string;
  book: string;
  category: string;
  year: number;
  url?: string;
};

export const AwardsUtil = {
  getImageStyle: (award: Award): CSSProperties => {
    return {
      backgroundImage: `url('awards/${award.id}-${award.year}.png')`,
    };
  },
};

export const Awards: Array<Award> = [
  {
    id: 'wattys',
    title: 'Die Wattys',
    book: 'The Shadows on your Soul',
    category: 'New Adult',
    year: 2020,
    url: 'https://www.wattpad.com/story/188865264-primed-for-sin-abgeschlossen-darkromance',
  },
  {
    id: 'sweekstars',
    title: 'Sweekstars',
    book: 'A Matter of TIME',
    category: 'Roman',
    year: 2018,
  },
];
