import React from 'react'

export type BookQuote = {
  text: string;
  author: string;
};

export type Vendor = {
  name: string;
  logoOverride?: string;
  url: string;
}

export type Book = {
  title: string;
  genre?: string;
  hideDetails?: boolean;
  isReleased?: boolean;
  year?: string;
  publisher?: string;
  description?: JSX.Element;
  coverName?: string;
  isFinalCover?: boolean;
  readNowUrl?: string;
  vendors?: Vendor[];
  quotes?: BookQuote[];
};

export const BooksUtil = {
  getTitle: (book: Book | undefined): string => {
    return book?.hideDetails ? 'Coming soon ...' : book?.title || ''
  },

  getSubtitle: (book: Book | undefined): string => {
    return book?.hideDetails ? '' : `${book?.publisher} | ${book?.year}`
  },

  getDescription: (book: Book | undefined): JSX.Element => {
    return book?.hideDetails
      ? <span>Weitere Infos zu diesem Projekt findet ihr bald hier oder etwas eher auf Instagram ;)</span>
      : book?.description || <span></span>
  }
}

export const Books: Array<Book> = [
  {
    title: 'The Shadows on your Soul',
    coverName: 'the-shadows-on-your-soul',
    publisher: 'Piper',
    description:
      <span>
        <span>
          <b>Eine Liebe, entstanden aus Rache – die düstere, romantische Liebesgeschichte zweier grundverschiedener Menschen</b>
        </span>
        <p>
          <i>"Wie hatte ich mich dazu hinreißen lassen können, Harper zu küssen? Ein Kuss zeugt im Gegensatz zu Sex von Emotionen. Von Gefühlen. Im schlimmsten Fall von Liebe."</i>
        </p>
        <p>
          Harper führt ein behütetes Leben in der Upper East Side Manhattans, Shaun muss nach dem frühen Tod seiner Eltern für sich und seinen kleinen Bruder sorgen. Als ihre vollkommen unterschiedlichen Welten miteinander kollidieren, wird beiden schnell klar, dass hinter dem jeweils anderen weit mehr steckt, als der erste Eindruck erkennen lässt. Doch was Harper nicht weiß: Dunkle Schatten und eine schwere Schuld lasten auf Shauns Schultern, und ihr erstes Treffen war kein Zufall …
        </p>
      </span>,
    year: 'Jetzt erhältlich',
    isReleased: true,
    isFinalCover: true,
    quotes: [
      {
        text: 'Seine Vergangenheit ist hart, so schnell kann sich ein Leben bzw. das einer gesamten Familie verändern ☹️',
        author: 'InessaAz - Wattpad'
      },
      {
        text: 'Ich bin echt überrascht, welche Wendung dein Buch genommen hat und wie Shaun und auch Harper sich entwickelt haben 😍😍',
        author: 'Tschania - Wattpad'
      },
      {
        text: 'Oooh so eine tolle Geschichte, hab sie gestern in einem Rutsch durchgelesen 😊',
        author: 'KriPetzi - Wattpad'
      },
      {
        text: 'Die Geschichte von Harper und Shaun hat mich ganz besonders fasziniert und ich werde sie bestimmt wieder lesen ❤️',
        author: 'Katie0805 - Wattpad'
      },
      {
        text: 'Das Buch ist ein Meisterwerk! Das ganze Buch, deine Schreibweise und die Beziehung, die sich Stück für Stück immer mehr zu eben dieser entwickelt hat, fasziniert mich! ❤️',
        author: 'OblivionBoy0704 - Wattpad'
      }
    ],
    vendors: [
      {
        name: 'piper',
        logoOverride: 'piper.png',
        url: 'https://www.piper.de/buecher/the-shadows-on-your-soul-isbn-978-3-492-50634-2'
      },
      {
        name: 'amazon',
        url: 'https://www.amazon.de/-/en/Fabienne-Herion/dp/3492506348'
      },
      {
        name: 'thalia',
        url: 'https://www.thalia.de/shop/home/artikeldetails/A1063555974'
      },
      {
        name: 'hugendubel',
        logoOverride: 'hugendubel.png',
        url: 'https://www.hugendubel.de/de/buch_kartoniert/fabienne_herion-the_shadows_on_your_soul-42378056-produkt-details.html'
      }
    ],
  },
  {
    title: 'The Way To Your Heart',
    genre: 'Romance',
    coverName: 'turnpike',
    year: 'Jetzt erhältlich',
    publisher: 'beHeartbeat (Bastei Lübbe)',
    hideDetails: false,
    isReleased: true,
    description: <span>Eine emotionale Reise quer durch die USA auf der Suche zu sich selbst. Amber weiß nicht mehr weiter: Zuerst hat sie ihren Job verloren, um dann auch noch festzustellen, dass sie aus ihrer Wohnung geschmissen wurde. Völlig mittellos und verzweifelt sucht sie einen Unterschlupf für die Nacht – und findet einen schrottreifen, scheinbar verlassenen Mini-Bus. Doch verlassen ist er keinesfalls. Denn plötzlich steht dessen vollbärtiger, eigenbrötlerischer Besitzer Deacon vor ihr. Obwohl dieser wenig begeistert von dem blinden Passagier ist, lässt er Amber bleiben. Zusammen reisen sie quer durch die USA, leisten sich Gesellschaft, spenden sich Trost und kommen einander so schnell näher. Doch mit der Zeit werden beide von ihrer Vergangenheit eingeholt, und die stellt die zarten Gefühle auf eine harte Probe. Werden sie trotzdem zueinander finden?</span>,
    vendors: [
      {
        name: 'be',
        url: 'https://www.luebbe.de/be/buecher/romance/the-way-to-your-heart/id_8892749'
      },
      {
        name: 'amazon',
        url: 'https://www.amazon.de/Fabienne-Herion/dp/3741302538/'
      },
      {
        name: 'thalia',
        url: 'https://www.thalia.de/shop/home/artikeldetails/ID151926185.html'
      }
    ],
    quotes: [
      {
        text: 'Gefühlsbetont, turbulent und romantisch. Das volle Spektrum für einen Liebesroman.',
        author: 'Tasmaniandevil8, Lesejury'
      },
      {
        text: 'Absolut lesenswert, besonders für Fans der USA oder die, die es werden wollen.',
        author: 'Sumuel, Lesejury'
      },
      {
        text: 'Achtung Suchtgefahr!',
        author: 'Lea296, Lesejury'
      },
      {
        text: 'Ich konnte von der ersten bis zur letzten Seite mit Amber und Deacon mitfiebern.',
        author: 'Yvonne, Amazon'
      },
      {
        text: 'Das Buch beinhaltet einen der schönsten ersten Küsse, den ich je in einem Buch gelesen habe.',
        author: '@zeilenprisma, Instagram'
      },
      {
        text: 'Lest dieses Buch. Und wenn ihr es lest: Nehmt euch Zeit! Ihr werdet es nicht aus der Hand legen können.',
        author: '@zeilenprisma, Instagram'
      },
      {
        text: 'Es ist ein von der ersten bis zur letzten Seite rundum gelungener Debütroman.',
        author: '@books.music.more, Instagram'
      },
      {
        text: 'Ambers und Deacons Geschichte ist außergewöhnlich.',
        author: '@ramona_liest, Instagram'
      },
      {
        text: 'Ich hatte die ganze Zeit über das Gefühl, ich würde mit den Beiden zusammen in ihrem Mini Bus sitzen und die wundervollen Nationalparks erkunden.',
        author: '@hanging_in_books, Instagram'
      },
      {
        text: 'Es ist wunderbar zu lesen, wie die Liebe der beiden mit jedem Kilometer mehr wächst.',
        author: '@favourite_novels, Instagram'
      },
      {
        text: 'Eine sehr gefühlvolle Geschichte mit viel Tiefe und vielen ausführlichen Details, was mir sehr gut gefallen hat.',
        author: 'j.adomat, Thalia'
      }
    ]
  },
  {
    title: 'A Matter of TIME',
    genre: 'New Adult Romance',
    coverName: 'a-matter-of-time',
    description: <span>Aubrey ist Studentin an einer privaten Hochschule und könnte sich nicht mehr fehl am Platz fühlen. Zwischen all den von reichen Eltern verzogenen Kommilitonen, ist sie unsichtbar. Obwohl sie sich nicht gerade selten einsam fühlt, zieht sie das Studium durch, denn sie ist kein Mensch, der leicht aufgibt. Vor allem dann nicht, wenn so viel Geld involviert ist. Mr. Cummings unterrichtet BWL und ist Aubreys Dozent. Er ist einer der wenigen Lehrkräfte an der Hochschule, die den vollen Respekt der Studenten genießen und das, obwohl oder gerade weil ihm diese gleichgültig zu sein scheinen. Zahlreiche Gerüchte kursieren über ihn und sorgen dafür, dass ihn niemand behelligt. Ein seltsamer Zwischenfall während einer BWL Vorlesung führt dazu, dass Aubrey plötzlich nicht mehr so unsichtbar ist. Ob sie das nun wirklich glücklicher macht?</span>,
    isFinalCover: true,
    year: '2019',
    publisher: 'Wattpad',
    readNowUrl: 'https://www.wattpad.com/story/161284866-a-matter-of-time-amot-i-abgeschlossen'
  },
  {
    title: 'End of the Line',
    coverName: 'end-of-the-line',
    description: <span>Zwar arbeitet Doro in einer der renommiertesten Künstleragenturen in ganz Kanada, doch so glamourös gestaltet sich ihre Arbeit in der Verwaltung keineswegs. Eine unerwartete Beförderung lässt sie ihr Glück kaum glauben, doch nicht immer steckt hinter allem was glänzt Gold, denn das attraktive Äußere ihres neuen Chefs verbirgt mehr, als man auf den ersten Blick erkennen kann. Jeremys zynisches und exzentrisches Verhalten treibt nicht nur die Bosse der Agentur in den Wahnsinn. Als hätte Doro mit einem Frauenaufreißer nicht ohnehin schon alle Hände voll zu tun, gibt es da auch noch einen gewissen Juristen, der ihre Aufmerksamkeit zurückverlangt und ihr dabei gefährlich nahekommt...</span>,
    publisher: 'Wattpad',
    isFinalCover: true,
    year: '2020',
    readNowUrl: 'https://www.wattpad.com/story/208208329-end-of-the-line-abgeschlossen-bossromance'
  },
  {
    title: 'Gas Stop',
    genre: 'New Adult Romance',
    coverName: 'gas-stop',
    description: <span>Für Allie ist Weihnachten eigentlich die schönste Zeit im Jahr. Doch nachdem sie sich gerade erst von ihrem Freund getrennt hat, hat sie überhaupt keinen Sinn für all die Fröhlichkeit und besinnlichen Stunden, auf die sie sich bei ihrer Rückkehr in die heimatliche Kleinstadt bisher immer gefreut hat.Die Magie der Feiertage scheint für Allie unerträglich, bis sie bei einem unerwarteten Botengang auf Jackson trifft. Ihren früheren besten Freund, in den sie schon als kleines Mädchen verliebt war...</span>,
    isFinalCover: true,
    year: '2021',
    publisher: 'Wattpad',
    readNowUrl: 'https://www.wattpad.com/995130479-gas-stop-short-story-newadultromance-abgeschlossen'
  },
  {
    title: 'Frontier\'s Tale',
    coverName: 'frontiers-tale',
    hideDetails: true
  },
  {
    title: 'All those Lies',
    coverName: 'all-those-lies',
    hideDetails: true
  },
  {
    title: 'Footprints in the Sand',
    coverName: 'footprints-in-the-sand',
    hideDetails: true
  },
  {
    title: 'Uncooking',
    coverName: 'uncooking',
    hideDetails: true,
    description: <span>Seit unseren Tagen an der High School sind Maddie und ich unzertrennlich. Zumindest denken das unsere Kinder. Denn sie sind der einzige Grund, wieso wir noch gemeinsam in diesem protzigen Haus wohnen, in dem ich mich mit jedem Tag, der vergeht, mehr und mehr wie ein Fremder fühle. Jede Nacht streife ich an unserem alten Schlafzimmer vorbei, schaue meinen Kindern beim Schlafen zu und frage mich, wann ich die falsche Abzweigung genommen habe. Wie es passierte, dass mir das Restaurant wichtiger wurde, als meine eigene Familie. Ich habe resigniert. Mit jedem Versuch meiner eigenen Frau wieder näher zu kommen, stoße ich sie nur immer weiter von mir fort. Und vollkommen egal was ich tue: Ich mache alles nur noch schlimmer. Ich liebe Maddie. Aber vielleicht muss ich akzeptieren, sie genau deshalb gehen zu lassen. Weil sie ihr Leben weitergelebt hat. Weil sie es nicht verdient hat, sich so von mir behandeln zu lassen. Weil sie mich um die Scheidung gebeten hat.</span>,
    year: '2021',
    readNowUrl: 'https://www.wattpad.com/story/282466524-uncooking-von-madeleines-und-sternen-romance',
    isFinalCover: false
  },
]