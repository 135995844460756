import React from 'react';
import { Awards as AwardsCollection, AwardsUtil } from '../../data/awards';
import { FunctionComponent } from 'react';

export const Awards: FunctionComponent = () => {
  return (
    <div className={'awards'}>
      {AwardsCollection.map((award, i) => (
        <a className={'award-details'} key={i} href={award.url} target={'__blank'}>
          <div className={'award-image'} style={AwardsUtil.getImageStyle(award)} />
          <h2 className={'award-title'}>{award.title}</h2>
          <h3 className={'award-book'}>{award.book}</h3>
          <h3>
            {award.year} | {award.category}
          </h3>
        </a>
      ))}
    </div>
  );
};
