import React, { FunctionComponent } from 'react';
import { Vendor } from '../data/books'

type VendorsProps = {
  title: string;
  vendors?: Vendor[];
};

export const Vendors: FunctionComponent<VendorsProps> = ({ title, vendors }) => {
  const titleTag = title ? <h2>{title}</h2> : '';

  if (!vendors) {
    return <div className={'vendors'}/>
  }

  const linkTags = vendors.map((vendor, i) => (
    <a key={i} target={'_blank'} href={vendor.url}>
      <div className={'logo'} style={{ backgroundImage: `url('logos/${vendor.logoOverride || `${vendor.name}.svg`}')` }} />
    </a>
  ));

  return (
    <div className={'vendors'}>
      {titleTag}
      <div className={'logos'}>{linkTags}</div>
      ... und vielen mehr.
    </div>
  );
};
