import React, { FunctionComponent } from 'react';

type PlaylistProps = {
  title?: string;
  playlistId: string;
};

export const Playlist: FunctionComponent<PlaylistProps> = ({ title, playlistId }) => {
  const getTitle = () => (title ? <h2>{title}</h2> : <></>);
  const getPlaylistUrl = (id: string) => `https://open.spotify.com/embed/playlist/${id}`;

  return (
    <div className={'playlist'}>
      {getTitle()}
      <iframe
        src={getPlaylistUrl(playlistId)}
        height="380"
        frameBorder="0"
        allow="encrypted-media"
        className={'playlist-embedded'}
      />
    </div>
  );
};
