import React, { FunctionComponent } from 'react';
import { Vendors } from '../vendors';
import { Quote } from '../quotes/quote'
import { Book, Books } from '../../data/books'

type BookHeroProps = {
  book: Book;
};

export const BookHero: FunctionComponent<BookHeroProps> = ({ book }) => {
  const coverStyle = {
    backgroundImage: `url('books/${book.coverName}.png')`,
  };
  const subtitle = `${book.publisher} | ${book.year}`;

  const vendors = (
    <Vendors
      title={`Jetzt ${book.isReleased ? '' : 'vor'}bestellen bei`}
      vendors={book.vendors}
    />
  );

  return (
    <div className={'book-hero'}>
      <div className={'left'}>
        <div className={'cover large'}>
          <div className={'inner cover-turnpike'} style={coverStyle} />
        </div>
        <Quote book={Books[0]} />
      </div>
      <section>
        <div className={'title'}>
          <h1 className={'title'}>{book.title}</h1>
          <h2 className={'subtitle'}>{subtitle}</h2>
        </div>
        <div className={'book-description'}>
          <h2 className={'subtitle'}>{subtitle}</h2>
          {book.description}
          {vendors}
        </div>
      </section>
    </div>
  );
};
