import React, { FunctionComponent } from 'react'

export const News: FunctionComponent = () => {
  return (
    <>
      <div className={'news-post'}>
        <h1>Neue Agentur "Brauer"</h1>
        <h2>01.05.2023</h2>
        <p>
          Jetzt kann ich es endlich offiziell bekanntgeben: Ich habe eine neue Agentur an meiner Seite 😍 Meine Agentin von der Agentur Brauer und ich waren ab dem ersten Gespräch direkt auf einer Wellenlänge und es ging sofort los. Drückt mir bitte die Daumen, dass auch mein nächstes Buchbaby schon ganz bald ein neues Zuhause findet 🍀
        </p>
      </div>
      <div className={'news-post'}>
        <h1>Happy Book Birthday</h1>
        <h2>01.12.2022</h2>
        <p>
          Juhu! Seit heute können Harper und Shaun bei euch ins Regal einziehen 🥳 Endlich könnt ihr all die Geheimnisse rund um „The Shadows on your Soul“ lüften 🥰 Ich wünsche euch ganz viel Spaß dabei und würde mich wie immer sehr darüber freuen, wenn ihr auf den üblichen Plattformen ein kleines Review für mein Buch hinterlasst ❤️
        </p>
      </div>
      <div className={'news-post'}>
        <h1>Zweite Buchveröffentlichung</h1>
        <h2>08.04.2022</h2>
        <p>
          Das Buch, das meine Wattpad-Leser noch unter dem Titel „Primed for Sin“ kennen, wird schon am 01.12. unter dem neuen Namen „The Shadows on your Soul“ als Taschenbuch (dieses Mal mit Auflage und nicht BoD) und eBook erscheinen!!! 🎉❤️ Im Rahmen der Reihe <a href={'https://www.piper.de/wattpad'} target={'__blank'}>Wattpad@Piper</a> arbeite ich dieses Mal mit Piper zusammen, um Shaun & Harper einen Feinschliff zu verpassen, bevor sie bei euch ins Bücherregal einziehen.<br/><br/>
          Und das Beste: Ihr könnt das Buch schon JETZT vorbestellen 😱🥳🥂
        </p>
      </div>
      <div className={'news-post'}>
        <h1>Happy Release-Day</h1>
        <h2>30.07.2021</h2>
        <p>
          Ab heute könnt ihr Deacon & Amber nicht nur auf euren Reader laden, sondern auch als Taschenbuch in Händen
          halten 🥳 Begleitet die beiden auf einen ereignisreichen Roadtrip durch die USA und unterstützt mich bei
          meinem Debüt. Auch eine Rezension auf den gängigen Plattformen würde mir unheimlich viel bedeuten und ein
          großes Stück dazu beitragen, noch mehr Aufmerksamkeit auf die Geschichte zu lenken ❤️
        </p>
      </div>
    </>
  );
};
