import React, { FunctionComponent, useRef } from 'react'
import { withOrientationChange, isMobile } from 'react-device-detect'
import Layout from '../components/layout'
import { BookHero } from '../components/header/bookHero'
import { PageSection } from '../components/pageSection'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { IconImage } from '../components/iconImage'
import { Footer } from '../components/sections/footer'
import { MyBooks } from '../components/sections/myBooks'
import { Playlist } from '../components/sections/playlist'
import { SocialFeed } from '../components/sections/socialFeed'
import { Awards } from '../components/sections/awards'
import { Books } from '../data/books'
import { News } from '../components/sections/news'
import { graphql, useStaticQuery } from 'gatsby'

type IndexPageProps = {
  isLandscape: boolean;
  isPortrait: boolean;
};

const IndexPage: FunctionComponent<IndexPageProps> = ({ isPortrait }) => {
  const currentlyWritingImageSuffix = isMobile && isPortrait ? '__vertical' : ''
  const heroBook = Books[0]

  const gitData = useStaticQuery(graphql`
    query HeaderQuery {
      gitCommit(latest: { eq: true }) {
        hash
      }
    }
  `)

  return (
    <Layout hideVideoBanner={false}>
      <div className={'content-container first'}>
        <div className={'book-header'}>
          <BookHero book={heroBook}/>
        </div>
        <PageSection title={'News & Termine'} id={'news'}>
          <News/>
        </PageSection>
        <PageSection title={'My books'} id={'my-books'} childrenClassNames={'book-hero small'}>
          <MyBooks books={Books}/>
        </PageSection>
        <PageSection title={'Currently Writing'} id={'currently-writing'}>
          <div
            className={'full-width-image'}
            style={{
              backgroundImage: `url('currently-writing${currentlyWritingImageSuffix}.png?v=${gitData.gitCommit.hash}')`
            }}
          />
        </PageSection>
        <PageSection title={'Awards'} id={'awards'}>
          <Awards/>
        </PageSection>
        <PageSection title={'My playlists'} id={'playlists'}>
          <div className={'flex-container playlist-container'}>
            <Playlist title={'The Shadows on your Soul'} playlistId={'7etLcCcKSyGKICvE57oNLM'}/>
            <Playlist title={'The Way to Your Heart'} playlistId={'29sh3tt6p3qAA7Gb1sd7mC'}/>
            <Playlist title={'End of the Line'} playlistId={'2PpbquZPvAtIzyBjvMbyUq'}/>
            <Playlist title={'AMOT I & II'} playlistId={'6LYQHAMLRljyQ3ADY2IzrN'}/>
          </div>
        </PageSection>
        <PageSection title={'About me'} id={'about-me'}>
          <p className={'about-me'}>
            <div className={'about-image'}/>
            Hi, ich bin Fabienne,
            <br/>
            <br/>
            ich wurde 1994 in Karlsruhe geboren und wohne in der schönen Südpfalz. Zum Schreiben kam ich, wie ganz viele
            andere Autoren auch, schon sehr früh. Tatsächlich war mein erster Schreibversuch irgendwann in der dritten
            Klasse, als ich die glorreiche Idee hatte, den “Der Herr der Ringe” umzuschreiben. Ich war und bin bis heute
            ein riesiger Tolkien-Fan und dachte damals, ich könnte so seine Geschichten noch vielen anderen
            näherbringen. Albern, das weiß ich heute &#x1F609; In den letzten Jahren habe ich einige meiner Geschichten
            auf
            Wattpad veröffentlicht.
            <br/>
            <br/>
            Wenn ich nicht gerade schreibe, bin ich passionierte Dosenöffnerin von unseren beiden Fellnasen Lani & Zuko
            (ja, ich bin auch großer Avatar-Fan). Zusammen mit meinem Freund habe ich vor einiger Zeit das Wandern für
            mich entdeckt. Wobei es dabei für uns immer ein schönes Ziel geben muss. Einige von euch kennen sicherlich
            meine Burgenbilder &#x1F609; Wer mir also auf Instagram folgt findet neben Büchern auch ab und an ein paar
            Naturaufnahmen. Für mich das beste Mittel um den Kopf freizubekommen und Ideen zu sammeln. Meine Bücher
            spielen alle in den USA, welches mein absolutes Lieblingsreiseziel darstellt. An vielen Orten war ich selbst
            schon oder plane sie bald zu besuchen. Neue Kulturen und Länder zu entdecken ist neben dem Schreiben eine
            meiner größten Leidenschaften.
            <br/>
            <br/>
            Schreibt mir gerne auch per Mail oder via Instagram. Ich beiße nicht und freue mich sehr über eure
            Nachrichten!
          </p>
          <p className={'about-agency'}>
            <br />Ich werde vertreten durch die<br />
            <a href="https://agentur-brauer.de/" target="_blank"><img className={'logo'} src="https://agentur-brauer.de/wp-content/uploads/2014/11/logo2014m1.jpg" width="300" alt="Agentur Brauer"/></a>
          </p>
        </PageSection>
        <PageSection title={'Follow me'} id={'follow-me'}>
          <SocialFeed/>
        </PageSection>
        <div className={'flex-container vertical wattpad-container'}>
          <a href={'https://www.wattpad.com/user/Dumai94'} target={'_new'}>
            <IconImage icon={'wattpad'}/>
            @Dumai94
          </a>
        </div>
        <Footer/>
      </div>
    </Layout>
  )
}

const IndexPageWithOrientationChange = withOrientationChange(IndexPage)

export default IndexPageWithOrientationChange
