import React, { FunctionComponent, useState } from 'react';
import { ajax } from 'rxjs/ajax';
import { InstagramFeed } from '../instagramFeed/instagramFeed'

type InstagramAccessToken = {
  access_token: string;
  token_type: string;
  expires_in: number;
};

export const 
  SocialFeed: FunctionComponent = () => {
  const [token, setToken] = useState('not_set_code');

  if (typeof XMLHttpRequest !== 'undefined') {
    ajax({
      url: 'instagram-token.dat',
      crossDomain: true,
      createXHR: function () {
        return new XMLHttpRequest();
      },
    }).subscribe(res => {
      const json = res.response;
      const accessTokenResponse = json as InstagramAccessToken;

      console.log(accessTokenResponse.access_token);
      setToken(accessTokenResponse.access_token);
    });
  }

    return (
    <>
      {/* @ts-ignore */}
      {token.indexOf('not_set') === -1 ? <InstagramFeed token={token} counter="5" /> : 'Zurzeit leider nicht verfügbar.'}
      <div className={'flex-container'}>
        <a
          className={'button button-instagram'}
          href={'https://www.instagram.com/fabienneherion/'}
          target={'_new'}
          aria-label={'Follow me on Instagram'}
        >
          Follow me on Instagram!
        </a>
      </div>
    </>
  );
};
