import React, { FunctionComponent, useEffect, useState } from 'react'
import { Book, BookQuote } from '../../data/books';
import { shuffle } from '../util';

type QuoteProps = {
  book: Book;
};

const shuffleIntervalMs = 5000;
const animationIntervalMs = 1000;

export const Quote: FunctionComponent<QuoteProps> = ({ book }) => {
  const getQuoteIndices = (): number[] => [...Array(book.quotes!.length - 1).keys()];

  let quoteIndices = getQuoteIndices();

  const hide = () => {
    setClassName('fade-out');
    setTimeout(() => showWithNewQuote(), animationIntervalMs);
  };

  const showWithNewQuote = () => {
    setQuote(getRandomQuote());
    setClassName('fade-in');
    setTimeout(() => runAnimationLoop(), animationIntervalMs);
  };

  const runAnimationLoop = () => {
    setTimeout(() => hide(), shuffleIntervalMs);
  };

  const getRandomQuote = (): BookQuote => {
    if (quoteIndices.length === 0) {
      quoteIndices = getQuoteIndices();
    }

    const index = shuffle(quoteIndices)[0];
    quoteIndices = quoteIndices.filter(i => i !== index);

    return book.quotes![index];
  }

  const [quote, setQuote] = useState<BookQuote>(getRandomQuote());
  const [className, setClassName] = useState<string>('');

  useEffect(() => {
    runAnimationLoop();
  }, []);

  const content = (
    <section className={`quote fade ${className}`}>
      <p className={'content'}>
        <p className={'quote'}>{quote?.text}</p>
        <p className={'author'}>{quote?.author}</p>
      </p>
    </section>
  );

  return book.quotes !== undefined && content;
};
